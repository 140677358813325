import http from "@/httpCommon";

export default {
  getImportUploadList(){
    return http.get(`/import-uploads`);
  },

  deleteImportUpload(import_upload_id){
    return http.delete(`/import-uploads/${import_upload_id}`);
  },
}
