<template>
  <v-dialog
    :value="dialog"
    @input="$emit('input', $event)"
    persistent
    max-width="600px"
  >
    <v-card>
      <v-card-title class="py-1">
        <!-- お知らせタイトル -->
        <v-row align="center" no-gutters>
          <v-col cols="5">
            <v-text-field
              label="タイトル"
              :error-messages="
                validationErrors.title ? validationErrors.title : ''
              "
              dense
              hide-details
              v-model="newTitle"
              outlined
              class="py-2"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6" v-if="id">
            <!-- 投稿日時や更新日時 -->
            <span v-if="createdAt" class="subtitle-1"
              >投稿日時:{{ createdAt.slice(0, -3) }}</span
            >
            <span v-if="createdAt !== updatedAt" class="subtitle-1">
              (更新:{{ updatedAt.slice(0, -3) }})</span
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- 関連商品 -->
        <v-row align="center" no-gutters>
          <v-col cols="8">
            <v-text-field
              label="関連商品"
              dense
              v-model="newRelatedProducts"
              outlined
              class="py-2"
              :error-messages="
                validationErrors.relatedProducts
                  ? validationErrors.relatedProducts
                  : ''
              "
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row align="center" no-gutters>
          <v-col>
            <!-- お知らせ詳細 -->
            <v-textarea
              label="詳細"
              dense
              no-resize
              rows="10"
              v-model="newContent"
              outlined
              class="py-2"
              :error-messages="
                validationErrors.content ? validationErrors.content : ''
              "
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <!-- 掲載期間 -->
        <v-row align="center" no-gutters style="margin-top: 5px">
          <v-col cols="5">
            <v-text-field
              type="date"
              label="掲載開始日"
              class="py-2"
              v-model="newNotificationStartDate"
              outlined
              dense
              :error-messages="
                validationErrors.notificationStartDate
                  ? validationErrors.notificationStartDate
                  : ''
              "
              hide-details
            />
          </v-col>
          <v-col cols="2">
            <div class="text-h5 text-center">～</div>
          </v-col>
          <v-col cols="5">
            <v-text-field
              type="date"
              label="掲載終了日"
              class="py-2"
              v-model="newNotificationEndDate"
              outlined
              dense
              :error-messages="
                validationErrors.notificationEndDate
                  ? validationErrors.notificationEndDate
                  : ''
              "
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>
      <v-card-actions>
        <!-- 閉じるボタン -->

        <v-spacer></v-spacer>
        <v-btn color="primary" @click="save" :disabled = "!!deleteAt || !newTitle">保存</v-btn>
        <v-btn color="warning" @click="close">閉じる</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
    <slot name="snackbarValidationErrors"></slot>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    id: {
      type: Number,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    content: {
      type: String,
      default: null,
    },
    relatedProducts: {
      type: String,
      default: null,
    },
    notificationStartDate: {
      type: String,
      default: null,
    },
    notificationEndDate: {
      type: String,
      default: null,
    },
    postPeriod: {
      type: String,
      default: null,
    },
    createdAt: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: null,
    },
    deleteAt: {
      type: String,
      default: null,
    },    validationErrors: {
      type: Object,
    },
  },
  data() {
    return {
      newTitle: null,
      newContent: null,
      newRelatedProducts: null,
      newNotificationStartDate: null,
      newNotificationEndDate: null,
    };
  },
  watch: {
    dialog(visible) {
      if (visible) {
        // Dialog was opened!
        this.newTitle = this.title;
        this.newContent = this.content;
        this.newRelatedProducts = this.relatedProducts;
        this.newNotificationStartDate = this.notificationStartDate;
        this.newNotificationEndDate = this.notificationEndDate;
      }
    },
  },
  methods: {
    save() {
      this.$emit('close-dialog');
      const notification = {
        id: this.id,
        title: this.newTitle,
        content: this.newContent,
        relatedProducts: this.newRelatedProducts,
        notificationStartDate: this.newNotificationStartDate,
        notificationEndDate: this.newNotificationEndDate,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
      };
      if (this.id === null) {
        this.$emit('create-notification', notification);
      } else {
        this.$emit('update-notification', notification);
      }
    },
    close() {
      this.$emit('close-dialog');
    },
  },
};
</script>
<style scoped>
.mini-size {
  transform: scale(0.85);
  transform-origin: left;
}
.mini-size-right {
  transform: scale(0.85);
  transform-origin: right;
}
</style>
