<template>
  <v-row>
    <v-col cols="6" class="pb-0 pr-0">
      <DashStatus
        :p-title="title1"
        :p-subtitle="subtitle"
        :p-count="this.allCount"
        :p-bgcolor="color"
        :p-event-key="this.eventId1"
        :server-flag="this.serverTarget"
      />
    </v-col>
    <v-col cols="6" class="pb-0 pl-0">
      <DashStatus
        :p-title="title2"
        :p-subtitle="subtitle"
        :p-count="this.userCount"
        :p-bgcolor="color"
        :p-event-key="eventId2"
        :server-flag="this.serverTarget"
      />
    </v-col>
    <v-col cols="6" class="pb-0 pt-0 pr-0">
      <DashStatus
        :p-title="title3"
        :p-subtitle="subtitle3"
        :p-count="this.inquiryTypeCount"
        :p-bgcolor="color"
        :p-event-key="this.eventId3"
        :server-flag="this.serverTarget"
      />
    </v-col>
    <v-col cols="6" class="pb-0 pt-0 pl-0">
      <DashStatus
        :p-title="title4"
        :p-subtitle="subtitle4"
        :p-count="this.dealTypeCount"
        :p-bgcolor="color"
        :p-event-key="eventId4"
        :server-flag="this.serverTarget"
      />
    </v-col>
    <v-col cols="6" class="pl-8 pt-0">
        <label style="font-size: 19px">対象期間：</label>
        <div v-for="(item,index) in typeTimeTargetOptions" :key="index" style="margin-top: 5px">
          <v-row>
            <v-col cols="3" class="mt-2 pb-0">
              <v-radio-group
                v-model="typeTimeTarget"
                row
                hide-details
                class="mt-0 pt-0"
                tabindex="1"
              >
                <v-radio
                  :key="index"
                  :label="item"
                  :value="index"
                  class="mr-3"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="9" class="pb-0">
              <v-select
                v-if="item == '月別'"
                v-model="monthlyTarget"
                :items="monthlyTargetOptions"
                style="margin-left: -10px!important;"
                item-text="label"
                item-value="value"
                outlined
                dense
              >
              </v-select>
              <div v-else class="mb-5">
                <v-row>
                  <v-col>
                    <v-text-field
                      type="date"
                      v-model="startDate"
                      label="お問い合わせ日（から）"
                      class="pa-0 ma-0"
                      style="margin-left: -10px!important;"
                      outlined
                      dense
                      hide-details
                    />
                    <p v-if="typeTimeTarget == 1 && !startDate" style="color: red">お問い合わせ日（から）は必須です。</p>
                  </v-col>
                  <v-col cols="1"></v-col>
                  <v-col>
                    <v-text-field
                      type="date"
                      v-model="endDate"
                      label="お問い合わせ日（まで）"
                      class="pa-0 ma-0"
                      style="margin-left: -10px!important;"
                      outlined
                      dense
                      hide-details
                    />
                    <p v-if="typeTimeTarget == 1 && !endDate" style="color: red; margin-bottom: 0px">お問い合わせ日（まで）は必須です。</p>
                    <div class="py-3" style="text-align: end; margin-left: -10px!important;">
                      <v-btn
                        @click="changeSearchTimeTarget"
                        elevation="5"
                        width="130"
                        color="primary"
                        style="margin-right: 7px!important; border-radius: 5px"
                        class="white--text py-5"
                      > グラフ表示
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
    </v-col>
    <v-col cols="6" class="pl-10 pt-0">
      <v-row class="m-0">
        <v-col cols="10">
          <label style="font-size: 19px; margin-left: -20px">対象項目：</label>
          <v-select
            v-model="itemTarget"
            :items="itemTargetOptions"
            item-text="label"
            item-value="value"
            style="min-height: 20px!important; padding-bottom: 0px; padding-top: 5px; margin-left: -20px!important;"
            outlined
            dense
          >
          </v-select>
          <label style="font-size: 19px; margin-left: -20px">対象受付窓口：</label>
          <v-select
            v-model="serverTarget"
            :items="serverOptions"
            item-text="label"
            item-value="value"
            style="min-height: 20px!important; padding-bottom: 0px; padding-top: 5px; margin-left: -20px!important;"
            outlined
            dense
          >
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="pt-0 pb-0" style="margin-top: -60px">
      <v-row class="mt-10" vs-justify="space-between" style="margin-bottom:0px;">
        <v-col cols="12" v-if="isShowChart ? (!dataBarChart || dataBarChart.length == 0) : false" class="text-center">
          <span>表示するようにデータがない</span>
        </v-col>
        <v-col cols="12" class="py-0 d-flex" style="justify-content: end; align-items: center; text-align: end; margin-left: -20px!important;" v-if="dataPieChart && dataPieChart.length > 0 || dataBarChart && dataBarChart.length > 0">
          <download-csv ref="export" :fields="fieldExport" :data="dataExport" :labels="labelExport" :name="titleCSV"
                        style="display: none;"></download-csv>
          <label style="font-size: 19px; margin-left: -20px">表示単位：</label>
          <v-radio-group
            v-model="displayUnitsState"
            row
            hide-details
            class="mt-0 pt-0"
          >
            <v-radio
              v-for="state in displayUnitsList"
              :key="state.value"
              :label="state.label"
              :value="state.value"
              :disabled="typeTimeTarget == 0 && state.value == 0"
              class="mr-3"
            ></v-radio>
          </v-radio-group>
          <v-btn @click="exportData"
                 rounded
                 elevation="5"
                 width="150"
                 color="primary"
                 class="white--text ml-10"
          >CSV出力
          </v-btn>
        </v-col>
        <v-col cols="4" v-show="dataPieChart && dataPieChart.length > 0">
          <PieChart
            :width="300"
            :height="300"
            :dataPieChart = "dataPieChart"
            :label= "labelPieChart"
          />
        </v-col>
        <v-col cols="8" v-show="dataBarChart && dataBarChart.length > 0" style="margin-left: -20px!important;">
          <BarChart
            :height="300"
            :dataBarChart = "dataBarChart"
            :label= "labelBarChart"
          />
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="px-8 pt-4 mb-4">
        <div class="px-8 pt-5" style="margin-top: 5px; border-top: 1px solid;">
          <v-row>
            <v-col  class="mt-2 pb-0">
              <label style="font-size: 19px">アンケート: </label>
            </v-col>
            <v-col  class="mt-2 pb-0">
              <label style="font-size: 19px">回答日時 </label>
            </v-col>
            <v-col cols="8" class="pb-0">
              <div  class="mb-5">
                <v-row>
                  <v-col class="px-0">
                    <v-text-field
                      type="date"
                      v-model="startDateSurvey"
                      label="開始日"
                      :disabled="noDateSpecified"
                      :max="maxYearDate"
                      class="pa-0 ma-0"
                      outlined
                      dense
                      hide-details
                    />
                    <p v-if="!noDateSpecified && !startDateSurvey" style="color: red">お問い合わせ日（から）は必須です。</p>
                  </v-col>
                  <!-- 「～」 -->
                  <v-col cols="1">
                      <p class="text-center text-h5 mb-0">～</p>
                  </v-col>
                  <!-- 終了日 -->
                  <v-col class="px-0">
                    <v-text-field
                      type="date"
                      v-model="endDateSurvey"
                      label="終了日"
                      :disabled="noDateSpecified"
                      :max="maxYearDate"
                      class="pa-0 ma-0"
                      outlined
                      dense
                      hide-details
                    />
                    <p v-if="!noDateSpecified && !endDateSurvey" style="color: red">お問い合わせ日（まで）は必須です。</p>
                  </v-col>
                  <v-col cols="4" class="ml-5 d-flex" style="justify-content:space-between;">
                    <v-checkbox
                      ref="noDateSpecified"
                      v-model="noDateSpecified"
                      label="日付指定なし"
                      class="d-inline-flex mini-size"
                      dense
                    ></v-checkbox>
                    <v-btn
                        @click="searchSurveyTimeTarget"
                        elevation="5"
                        width="130"
                        color="primary"
                        style="border-radius: 5px"
                        class="white--text py-5"
                      > 結果表示
                      </v-btn>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </div>
    </v-col>

    <v-col cols="12" class="pt-0 pb-0">
      <v-row class="mx-2" style="justify-content: space-evenly;">
        <v-col :cols="4" class="px-4" :class="noDataAnswersSurvey(item.answers) ? 'd-none' : ''" v-for="(item, index) in pieChartSurveyData"  :key="index">
          <p class="font-weight-bold mb-4 font-size-14">{{ item.question }}</p>
          <p v-if="noDataAnswersSurvey(item.answers)" class="my-6 text-center font-size-14">表示するようにデータがない</p>
          <PieCustom
            v-else
            :chartId="`answers-chart-${index}`"
            :dataPieChart="item.answers"
						:reload="reloadChart"
            :position="'right'"
            :colorScheme="[...colorSurveyChart, ...colorScheme]"
            :cssClasses="'answers-chart'"
          />
        </v-col>
        <v-col cols="12" v-if="isShowSurveyChart && (noDateSpecified || (endDateSurvey && startDateSurvey)) && (!pieChartSurveyData || pieChartSurveyData.length === 0) " class="text-center">
          <span>表示するようにデータがない</span>
        </v-col>
      </v-row>
    </v-col>
    <v-overlay :value="loadingCount > 0">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import DashStatus from '@/components/DashStatus.vue'
import getMatterCountService from '@/services/getMatterCountService.js'
import getDataDashBoardService from '@/services/getDataDashBoardService.js'
import questionsSurveyCsvService from '@/services/questionsSurveyCsvService.js';
import PieChart from "../components/chart/PieChart.vue";
import PieCustom from "../components/chart/PieCustom.vue";
import BarChart from "../components/chart/BarChart.vue";
import subtypeServices from "@/services/subtypeServices";
import constants from '@/components/helpers/const';

export default {
  name: 'DashBoard',
  // 画面表示時に行う処理
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.storeHeaderTitleUpdate();
            next();
        });
    },
  data: function () {
    return {
      title1: '対応状況（当月1ヶ月／全体）',
      title2: '対応状況（当月1ヶ月／担当分）',
      title3: '受付手段（当月1ヶ月／全体）',
      title4: '対応手段（当月1ヶ月／全体）',
      subtitle: ['未対応', '対応中', '返送待ち', '商品手配中', '対応済み', '未達完了', 'チェック'],
      subtitle3: ['メール', '電話', '郵送', '来社', '他部署転送', 'その他'],
      subtitle4: ['メール', '電話', '郵送', '来社', '他部署依頼', 'その他'],
      eventId1: [0, 1, 2, 3, 4, 5 ,6],
      eventId2: [7, 8, 9, 10, 11, 12 ,13],
      eventId3: [14, 15, 16, 17, 18, 19],
      eventId4: [20, 21, 22, 23, 24, 25],
      allCount: [0, 0, 0, 0, 0, 0, 0],
      userCount: [0, 0, 0, 0, 0, 0, 0],
      inquiryTypeCount: [0, 0, 0, 0, 0, 0],
      dealTypeCount: [0, 0, 0, 0, 0, 0],
      color: ['faebd7', 'FFFFFF', 'afeeee', 'e6e6fa', 'aed6f7', 'f4ffc7', 'ead7eb'],
      usrId: 1,
      timerid: undefined,
      count: true,
      // loading時間
      loadingCount: 0,
      typeTimeTargetOptions: ["月別","期間指定"],
      itemTarget: "statusFlg",
      itemTargetOptions:[
        {
          label: "状態",
          value: "statusFlg",
        },
        {
          label: "問合せ区分",
          value: "categoryFlg",
        },
        {
          label: "小区分",
          value: "subType",
        },
        {
          label: "受付手段",
          value: "inquiryType",
        },
        {
          label: "対応手段",
          value: "dealType",
        },
        {
          label: "お客様種別",
          value: "customerTypeFlg",
        }
      ],
      serverTarget: constants.serverFlag.webcs,
      serverOptions: [
        {
          label: constants.serverLaBel.webcs,
          value: constants.serverFlag.webcs,
        },
        {
          label: constants.serverLaBel.oshop,
          value: constants.serverFlag.oshop,
        },
        {
          label: constants.serverLaBel.sid,
          value: constants.serverFlag.sid,
        },
        {
          label: constants.serverLaBel.mo,
          value: constants.serverFlag.mo,
        }
      ],
      listStatusFlg: [
        {
          value: 0,
          label: "未対応",
        },
        {
          value: 1,
          label: "対応中",
        },
        {
          value: 2,
          label: "返送待ち",
        },
        {
          value: 3,
          label: "商品手配中",
        },
        {
          value: 4,
          label: "対応済み",
        },
        {
          value: 5,
          label: "未達完了",
        },
        {
          value: 6,
          label: "未設定",
        },
      ],
      listCategoryFlg: [
        {
          value: 1,
          label: "お問合せ",
        },
        {
          value: 2,
          label: "苦情",
        },
        {
          value: 3,
          label: "ご意見",
        },
        {
          value: 4,
          label: "その他",
        },
        {
          value: 5,
          label: "未設定",
        },
      ],
      listInquiryType: [
        {
          value: 0,
          label: "メール",
        },{
          value: 1,
          label: "電話",
        },
        {
          value: 2,
          label: "郵送",
        },
        {
          value: 3,
          label: "来社",
        },
        {
          value: 4,
          label: "他部署転送",
        },
        {
          value: 5,
          label: "その他",
        },
      ],
      listDealType: [
        {
          value: 0,
          label: "メール",
        },{
          value: 1,
          label: "電話",
        },
        {
          value: 2,
          label: "郵送",
        },
        {
          value: 3,
          label: "来社",
        },
        {
          value: 4,
          label: "他部署依頼",
        },
        {
          value: 5,
          label: "その他",
        },
      ],
      listCustomerTypeFlg: [
        {
          value: 0,
          label: '個人消費者',
        },
        {
          value: 1,
          label: '企業消費者',
        },
        {
          value: 2,
          label: '販売店',
        },
        {
          value: 3,
          label: '支店',
        },
        {
          value: 4,
          label: 'その他',
        },
      ],
      typeTimeTarget: 0,
      monthlyTarget: "",
      startDate: null,
      endDate: null,
      isShowChart: false,
      dataPieChart: [],
      //105 colors
      colorScheme: [
        "#1ABC9C","#2ECC71","#3498DB","#9B59B6","#34495E",
        "#F1C40F","#E67E22","#E74C3C","#ECF0F1","#95A5A6",
        "#FEA47F","#25CCF7","#EAB543","#55E6C1","#CAD3C8",
        "#F97F51","#1B9CFC","#F8EFBA","#58B19F","#2C3A47",
        "#B33771","#3B3B98","#FD7272","#9AECDB","#D6A2E8",
        "#6D214F","#182C61","#FC427B","#BDC581","#82589F",
        "#FFC312","#C4E538","#12CBC4","#FDA7DF","#ED4C67",
        "#F79F1F","#A3CB38","#1289A7","#D980FA","#B53471",
        "#EE5A24","#009432","#0652DD","#9980FA","#833471",
        "#EA2027","#006266","#1B1464","#5758BB","#6F1E51",
        "#00A8FF","#9C88FF","#FBC531","#4CD137","#487EB0",
        "#0097E6","#8C7AE6","#E1B12C","#44BD32","#40739E",
        "#E84118","#F5F6FA","#7F8FA6","#273C75","#353B48",
        "#FF9FF3","#FECA57","#FF6B6B","#48DBFB","#1DD1A1",
        "#F368E0","#FF9F43","#EE5253","#0ABDE3","#10AC84",
        "#00D2D3","#54A0FF","#5F27CD","#C8D6E5","#576574",
        "#01A3A4","#2E86DE","#341F97","#8395A7","#222F3E",
        "#CD84F1","#FFCCCC","#FF4D4D","#FFAF40","#FFFA65",
        "#C56CF0","#FFB8B8","#FF3838","#FF9F1A","#FFF200",
        "#32FF7E","#7EFFF5","#18DCFF","#7D5FFF","#4B4B4B",
        "#3AE374","#67E6DC","#17C0EB","#7158E2","#3D3D3D",
      ],
      dataBarChart: [],
      labelBarChart: [],
      labelPieChart: [],
      dataExport: [],
      labelExport: {},
      titleCSV: "",
      fieldExport: [],
      dataDashBoard: '',
      listSubType: [],
      displayUnitsList: [
        { value: 0, label: '年' },
        { value: 1, label: '月' },
        { value: 2, label: '日' },
      ],
      displayUnitsState: 2,
      responseDataChart: [],
      startDateSurvey: null,
      endDateSurvey: null,
      noDateSpecified: true,
      pieChartSurveyData: [],
      isShowSurveyChart: false,
      colorSurveyChart: ['#5b9bd5', '#ed7d31', '#a5a5a5'],
      reloadChart: "",
      maxYearDate: (new Date().getFullYear()) + '-12-31',
    }
  },
  methods: {
    // VuexのstateのheaderTitleを更新
    storeHeaderTitleUpdate() {
      this.$store.dispatch('headerTitleChangeAction', 0);
    },
    getMatterCount: async function () {
      try {
        const response = await getMatterCountService.getMatterCount( this.usrId, this.serverTarget );
        this.allCount = [ response.data.data.allCount.beforeStart, response.data.data.allCount.inProgress,
           response.data.data.allCount.waitingReturn, response.data.data.allCount.arrangingProducts,
           response.data.data.allCount.finished, response.data.data.allCount.unfinished, response.data.data.allCount.checked ]
        this.userCount = [ response.data.data.userCount.beforeStart, response.data.data.userCount.inProgress,
           response.data.data.userCount.waitingReturn, response.data.data.userCount.arrangingProducts,
           response.data.data.userCount.finished, response.data.data.userCount.unfinished, response.data.data.userCount.checked ]
      } catch (e) {
        this.allCount = [0, 0, 0, 0, 0, 0, 0]
        this.userCount = [0, 0, 0, 0, 0, 0, 0]
        console.log(e);
      }
    },
    getTypeMatterCount: async function () {
      try {
        const response = await getDataDashBoardService.getTypeMatterCount(this.serverTarget);
        this.inquiryTypeCount = [ response.data.data.inquiryType.email, response.data.data.inquiryType.phone,
           response.data.data.inquiryType.mail, response.data.data.inquiryType.visit,
           response.data.data.inquiryType.transferToOtherDepartment, response.data.data.inquiryType.others ]
        this.dealTypeCount = [ response.data.data.dealType.email, response.data.data.dealType.phone,
           response.data.data.dealType.mail, response.data.data.dealType.visit,
           response.data.data.dealType.otherDepartmentRequest, response.data.data.dealType.others ]
      } catch (e) {
        this.inquiryTypeCount = [0, 0, 0, 0, 0, 0]
        this.dealTypeCount = [0, 0, 0, 0, 0, 0]
        console.log(e);
      }
    },
    async getDashBoard(){
      this.loadingCount++;
      let paramsDashBoard = {
        typeTimeTarget : this.typeTimeTarget,
        monthlyTarget : this.monthlyTarget,
        startDate : this.startDate,
        endDate : this.endDate,
        itemTarget : this.itemTarget,
        serverFlag: this.serverTarget
      }
        let response = await getDataDashBoardService.getDataDashBoard(paramsDashBoard);
      if (this.itemTarget == 'subType' ){
        this.listSubType = [];
        let res = await subtypeServices.getSubtypeList();
        let subTypes = res.data.data;
        subTypes.forEach((element) => {
          let dataSubType = {
            value: element.id,
            label: element.name,
          }
          this.listSubType.push(dataSubType);
        });
        this.listSubType.push({
          value: this.listSubType.length + 1,
          label: '未設定',
        });
      }
        this.responseDataChart = response;
        this.renderDataBarChart(response);
        this.renderDataPieChart(response);
      this.loadingCount--;
    },
    async renderDataPieChart(response){
      this.labelPieChart = [];
      this.dataPieChart = [];
      let dataPieChart = [];
      let labelPieChart = [];
      let dataElement = {
        backgroundColor: [],
        data: [],
      }
      if (response.data.statusFlg){
        let dataStatusFlg = response.data.statusFlg.data;
        this.listStatusFlg.forEach((element) => {
          let label = element.label;
          let total = 0;
          for(const data in dataStatusFlg){
            let valueFlg = dataStatusFlg[data]
            let amount = 0;
            valueFlg[label] ? amount = valueFlg[label] : amount = 0;
            total += amount
          }
          if (total) {
            labelPieChart.push(label)
            dataElement.data.push(total);
            dataElement.backgroundColor.push(this.colorScheme[element.value]);
          }
        })
      }else if (response.data.categoryFlg){
        let dataCategoryFlg = response.data.categoryFlg.data;
        this.listCategoryFlg.forEach((element) => {
          let label = element.label;
          let total = 0;
          for(const data in dataCategoryFlg){
            let valueFlg = dataCategoryFlg[data]
            let amount = 0;
            valueFlg[label] ? amount = valueFlg[label] : amount = 0;
            total += amount
          }
          if (total) {
            labelPieChart.push(label)
            dataElement.data.push(total);
            dataElement.backgroundColor.push(this.colorScheme[element.value]);
          }
        })
      }else if (response.data.inquiryType){
        let dataInquiryType = response.data.inquiryType.data;
        this.listInquiryType.forEach((element) => {
          let label = element.label;
          let total = 0;
          for(const data in dataInquiryType){
            let valueFlg = dataInquiryType[data]
            let amount = 0;
            valueFlg[label] ? amount = valueFlg[label] : amount = 0;
            total += amount
          }
          if (total) {
            labelPieChart.push(label)
            dataElement.data.push(total);
            dataElement.backgroundColor.push(this.colorScheme[element.value]);
          }
        })
      }else if (response.data.dealType){
        let dataDealType = response.data.dealType.data;
        this.listDealType.forEach((element) => {
          let label = element.label;
          let total = 0;
          for(const data in dataDealType){
            let valueFlg = dataDealType[data]
            let amount = 0;
            valueFlg[label] ? amount = valueFlg[label] : amount = 0;
            total += amount
          }
          if (total) {
            labelPieChart.push(label)
            dataElement.data.push(total);
            dataElement.backgroundColor.push(this.colorScheme[element.value]);
          }
        })
      }else if (response.data.customerTypeFlg){
        let dataCustomerTypeFlg = response.data.customerTypeFlg.data;
        this.listCustomerTypeFlg.forEach((element) => {
          let label = element.label;
          let total = 0;
          for(const data in dataCustomerTypeFlg){
            let valueFlg = dataCustomerTypeFlg[data]
            let amount = 0;
            valueFlg[label] ? amount = valueFlg[label] : amount = 0;
            total += amount
          }
          if (total) {
            labelPieChart.push(label)
            dataElement.data.push(total);
            dataElement.backgroundColor.push(this.colorScheme[element.value]);
          }
        })
      }else if (response.data.subType){
        let dataSubType = response.data.subType.data;
          this.listSubType.forEach((element) => {
          let label = element.label;
          let total = 0;
          for(const data in dataSubType){
            let valueFlg = dataSubType[data]
            let amount = 0;
            valueFlg[label] ? amount = valueFlg[label] : amount = 0;
            total += amount
          }
            if (total) {
              labelPieChart.push(label)
              dataElement.data.push(total);
              dataElement.backgroundColor.push(this.colorScheme[element.value]);
            }
        })
      }
      if (dataElement.data.find(element => element > 0)){
        dataPieChart.push(dataElement);
      }
      this.dataPieChart = dataPieChart;
      this.labelPieChart = labelPieChart.length > 0 ? labelPieChart : [];
    },
    groupedDataRenderBarChart(dataStatusFlg) {
      const cutoffMonth = 6;
      const result = {};
      if (this.displayUnitsState !== 2 && dataStatusFlg && typeof dataStatusFlg === 'object') {
        Object.keys(dataStatusFlg).forEach(date => {
          const currentDate = new Date(date);
          let getFullYear = currentDate.getFullYear();

          let keyTimes;
          switch (this.displayUnitsState) {
            case 0:
              if (currentDate.getMonth() >= cutoffMonth) {
                getFullYear = (getFullYear + 1); //If month >= 7, add to next year
              }
              keyTimes = (getFullYear - 1) + '年';
              break;
            case 1:
              keyTimes = date.slice(0, 7)+'月';
              break;
            default:
              keyTimes = date+'日';
              break;
          }
          const values = dataStatusFlg[date];
          if (typeof values === 'object' && values !== null) {
              const japaneseKeys = new Map();
              Object.keys(values).forEach(key => {
                if (!japaneseKeys.has(key)) {
                  japaneseKeys.set(key,0);
                }
              });
              const resultObject = Object.fromEntries(japaneseKeys);

              result[keyTimes] = result[keyTimes] || resultObject;

              Object.keys(values).forEach(key => {
                result[keyTimes][key] = (result[keyTimes][key] || 0) + values[key];
              })
          }
        });
      }else{
        return dataStatusFlg;
      }
      return result;
    },
    async renderDataBarChart(response){
      this.labelBarChart = [];
      this.dataBarChart = [];
      let dataBarChart = [];
      let labelBarChart = [];
      if (response.data.statusFlg){
        let dataStatusFlg = response.data.statusFlg.data;
        this.dataDashBoard = dataStatusFlg;
        dataStatusFlg = this.groupedDataRenderBarChart(dataStatusFlg);
        for(const data in dataStatusFlg){
          labelBarChart.push(data)
        }
        this.listStatusFlg.forEach((element) => {
          let label = element.label;
          let dataElement = {
            label: label,
            backgroundColor: this.colorScheme[element.value],
            data: [],
          }
          for(const data in dataStatusFlg){
            let valueFlg = dataStatusFlg[data]
            for(const dataDetail in valueFlg){
              if (dataDetail == label){
                dataElement.data.push(valueFlg[label])
              }
            }
          }
          if (dataElement.data.find(element => element > 0)){
            dataBarChart.push(dataElement);
          }
        })
      }else if (response.data.categoryFlg){
        let dataCategoryFlg = response.data.categoryFlg.data;
        this.dataDashBoard = dataCategoryFlg;
        dataCategoryFlg = this.groupedDataRenderBarChart(dataCategoryFlg);
        for(const data in dataCategoryFlg){
          labelBarChart.push(data)
        }
        this.listCategoryFlg.forEach((element) => {
          let label = element.label;
          let dataElement = {
            label: label,
            backgroundColor: this.colorScheme[element.value],
            data: [],
          }
          for(const data in dataCategoryFlg){
            let valueFlg = dataCategoryFlg[data]
            for(const dataDetail in valueFlg){
              if (dataDetail == label){
                dataElement.data.push(valueFlg[label])
              }
            }
          }
          if (dataElement.data.find(element => element > 0)){
            dataBarChart.push(dataElement);
          }
        })
      }else if (response.data.inquiryType){
        let dataInquiryType = response.data.inquiryType.data;
        this.dataDashBoard = dataInquiryType;
        dataInquiryType = this.groupedDataRenderBarChart(dataInquiryType);
        for(const data in dataInquiryType){
          labelBarChart.push(data)
        }
        this.listInquiryType.forEach((element) => {
          let label = element.label;
          let dataElement = {
            label: label,
            backgroundColor: this.colorScheme[element.value],
            data: [],
          }
          for(const data in dataInquiryType){
            let valueFlg = dataInquiryType[data]
            for(const dataDetail in valueFlg){
              if (dataDetail == label){
                dataElement.data.push(valueFlg[label])
              }
            }
          }
          if (dataElement.data.find(element => element > 0)){
            dataBarChart.push(dataElement);
          }
        })
      }else if (response.data.dealType){
        let dataDealType = response.data.dealType.data;
        this.dataDashBoard = dataDealType;
        dataDealType = this.groupedDataRenderBarChart(dataDealType);
        for(const data in dataDealType){
          labelBarChart.push(data)
        }
        this.listDealType.forEach((element) => {
          let label = element.label;
          let dataElement = {
            label: label,
            backgroundColor: this.colorScheme[element.value],
            data: [],
          }
          for(const data in dataDealType){
            let valueFlg = dataDealType[data]
            for(const dataDetail in valueFlg){
              if (dataDetail == label){
                dataElement.data.push(valueFlg[label])
              }
            }
          }
          if (dataElement.data.find(element => element > 0)){
            dataBarChart.push(dataElement);
          }
        })
      }else if (response.data.customerTypeFlg){
        let dataCustomerTypeFlg = response.data.customerTypeFlg.data;
        this.dataDashBoard = dataCustomerTypeFlg;
        dataCustomerTypeFlg = this.groupedDataRenderBarChart(dataCustomerTypeFlg);
        for(const data in dataCustomerTypeFlg){
          labelBarChart.push(data)
        }
        this.listCustomerTypeFlg.forEach((element) => {
          let label = element.label;
          let dataElement = {
            label: label,
            backgroundColor: this.colorScheme[element.value],
            data: [],
          }
          for(const data in dataCustomerTypeFlg){
            let valueFlg = dataCustomerTypeFlg[data]
            for(const dataDetail in valueFlg){
              if (dataDetail == label){
                dataElement.data.push(valueFlg[label])
              }
            }
          }
          if (dataElement.data.find(element => element > 0)){
            dataBarChart.push(dataElement);
          }
        })
      }else if (response.data.subType){
        let dataSubType = response.data.subType.data;
        this.dataDashBoard = dataSubType;
        dataSubType = this.groupedDataRenderBarChart(dataSubType);
        for(const data in dataSubType){
          labelBarChart.push(data)
        }
        this.listSubType.forEach((element) => {
          let label = element.label;
          let dataElement = {
            label: label,
            backgroundColor: this.colorScheme[element.value],
            data: [],
          }
          for(const data in dataSubType){
            let valueFlg = dataSubType[data]
            for(const dataDetail in valueFlg){
              if (dataDetail == label){
                dataElement.data.push(valueFlg[label])
              }
            }
          }
          if (dataElement.data.find(element => element > 0)){
            dataBarChart.push(dataElement);
          }
        })
      }
      this.dataBarChart = dataBarChart;
      this.labelBarChart = labelBarChart.length > 0 ? labelBarChart : [];
    },
    setDataExport(){
      let dataTarget = []
      if (this.itemTarget == 'statusFlg') {
        dataTarget = this.listStatusFlg;
      } else if (this.itemTarget == 'categoryFlg') {
        dataTarget = this.listCategoryFlg;
      } else if (this.itemTarget == 'subType') {
        dataTarget = this.listSubType;
      } else if (this.itemTarget == 'inquiryType') {
        dataTarget = this.listInquiryType;
      } else if (this.itemTarget == 'dealType') {
        dataTarget = this.listDealType;
      } else if (this.itemTarget == 'customerTypeFlg') {
        dataTarget = this.listCustomerTypeFlg;
      }
      //Create label csv export
      const label = dataTarget.reduce((a, v) => ({ ...a, [this.itemTarget+v.value]:v.label}),{});
      this.fieldExport = [];
      this.dataExport = [];
      this.labelExport = {};
      this.fieldExport.push('date')
      for(const fieldLabel in label){
        this.fieldExport.push(fieldLabel)
      }
      this.labelExport = Object.assign({date: '\t'}, label);
      //Create data csv export
      let data = this.itemTarget ? this.dataDashBoard : [];
      let dateKey = data ? Object.keys(data).sort() : [];
      let dataExport = [];
      dateKey.forEach((date) =>{
        let dateExportChild = {};
        dateExportChild.date = date;
        dataTarget.forEach((element)=>{
          dateExportChild[this.itemTarget+element.value] = data[date][element.label] ? data[date][element.label] : 0;
        })
        dataExport.push(dateExportChild);
      })
      let date = new Date();
      this.titleCSV = 'report_'+date.getFullYear()+(date.getMonth()+1)+date.getDate()+date.getHours()+date.getMinutes()+date.getSeconds()+'.csv';
      this.dataExport = dataExport;
    },
    async exportData() {
      await this.setDataExport();
      this.$refs.export.generate()
    },
    async changeSearchTimeTarget() {
      if ((this.typeTimeTarget == 0 && !this.monthlyTarget) || (this.typeTimeTarget == 1 && (!this.startDate || !this.endDate))){
        this.dataPieChart = [];
        this.dataBarChart = [];
      } else {
        await this.getDashBoard();
        this.isShowChart= true;
      }
    },
    async searchSurveyTimeTarget() {
      if (!this.noDateSpecified && (!this.startDateSurvey || !this.endDateSurvey)){
        this.validate = true;
      } else {
        await this.getQuestionsSurveyDashBoard();
        this.isShowSurveyChart = true;
        this.reloadChart = this.noDateSpecified + this.startDateSurvey + this.endDateSurvey;
      }
    },
    async getQuestionsSurveyDashBoard(){
      this.loadingCount++;
      let params = {
        startDate : this.startDateSurvey,
        endDate : this.endDateSurvey,
        noDateSpecified: this.noDateSpecified
      };
      try {
        let response = await questionsSurveyCsvService.getSurveyReport(params);
        if(response.data && response.data.data) {
          // Check if all answers are zero
          const surveyData = response.data.data;
          const allZero = surveyData.every(item =>
            Object.values(item.answers).every(answer => answer === 0)
          );
          this.pieChartSurveyData = allZero ? []: surveyData ;
        }
      } catch (error) {
        this.pieChartSurveyData = [];
      }
      this.loadingCount--;
    },
    noDataAnswersSurvey(answers) {
      return Object.values(answers).every(answer => answer === 0);
    },
  },
  watch:{
    async typeTimeTarget(){
      if ((!this.startDate || !this.endDate) && this.typeTimeTarget == 2){
        this.validate = true;
        this.dataPieChart = [];
        this.dataBarChart = [];
      }
      if (this.typeTimeTarget == 0) {
        //表示単位  0:年, 1:月, 2:日
        if(this.displayUnitsState == 0) this.displayUnitsState = 2;
      }
    },
    async monthlyTarget(){
      this.typeTimeTarget = 0;
    },
    async startDate(){
      this.typeTimeTarget = 1;
    },
    async endDate(){
      this.typeTimeTarget = 1;
    },
    async itemTarget(){
      await this.getDashBoard();
    },
    async serverTarget(){
      await this.getDashBoard();
      await this.getMatterCount()
      await this.getTypeMatterCount()
    },
    async displayUnitsState(){
      if (this.responseDataChart.length !== 0) {
        this.renderDataBarChart(this.responseDataChart);
      }
    }
  },
  computed:{
    monthlyTargetOptions(){
      let monthlyTargetOptions = [];
      let is = this;
      for (let i = 0; i <= 12 ; i++){
        let monthly = new Date();
        monthly.setMonth(monthly.getMonth() - i);
        let formatMonthlyValue = monthly.toISOString().slice(0,7);
        let formatMonthlyLabel = i > 0 ? i+'ヵ月前('+formatMonthlyValue+')' : '今月('+formatMonthlyValue+')';
        let result = {
          value: formatMonthlyValue,
          label: formatMonthlyLabel
        }
        monthlyTargetOptions.push(result);
        if (i == 0 && (is.monthlyTarget == "" || !is.monthlyTarget)) is.monthlyTarget = result.value;
      }
      return monthlyTargetOptions;
    },
  },
  async mounted() {
    this.usrId = this.$store.getters['auth/authUser'].id;
    await this.getMatterCount()
    await this.getTypeMatterCount()
    this.timerid = setInterval( async () => {
      await this.getMatterCount()
      await this.getTypeMatterCount()
    }, 30000)
  },
  destroyed() {
   clearInterval( this.timerid )
 },
  components: {
    DashStatus,
    PieChart,
    BarChart,
    PieCustom,
  },
}
</script>
