
<script>
import { Pie } from "vue-chartjs";

export default {
    extends: Pie,
    props: {
        dataPieChart  : { type: [Object,Array], default:() => [] },
        reload : { type : [String,Array,Number,Boolean], default: ""},
        colorScheme: { type: [Object,Array], default:() => ["#5b9bd5", "#ed7d31", "#a5a5a5"] },
    },
    data () {
        return{
            labelChart: [],
            dataChart: [],
        }
    },
    watch:{
        reload(){
            this.reloadChart();
        }
    },
    methods:{
        renderPieChart(){
            this.renderChart(
                {
                  labels: this.labelChart,
                  datasets: [
                    {
                      backgroundColor: this.colorScheme,
                      data: this.dataChart,
                    }
                  ]
                },
                {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend:{
                        display: true,
                        labels: {
                            boxWidth: 13,
                            padding: 13,
                            margin: 0,
                            generateLabels: (chart) => {
                              const truncateLabel = (label, maxLength) => {
                                if (label.length > maxLength) {
                                  return label.slice(0, maxLength) + '...'; // Truncate and add ellipsis
                                }
                                return label;
                              };

                              const maxLabelLength = 10; // Set your desired max length for the label

                              const labels = chart.data.labels.map((label, index) => ({
                                text: truncateLabel(label, maxLabelLength), // Custom truncated label text
                                fillStyle: chart.data.datasets[0].backgroundColor[index], // Color
                                index,
                                hidden: chart.getDatasetMeta(0).data[index].hidden,
                              }));
                              return labels;
                            },
                        },
                        position: 'right',//right bottom
                    },
                    animation: {
                      duration: 2000,
                      easing: "easeInOutQuart",

                    },
                }
            );
        },
        reloadChart(){
            this.labelChart = []
            this.dataChart = []
            Object.entries(this.dataPieChart).forEach(([key, value]) => {
                if (value > 0){
                    this.labelChart.push(key);
                    this.dataChart.push(value)
                }
            });
            this.renderPieChart()
        },

    },
    created(){
        // this.reloadChart();
    }
};
</script>

<style scoped>
    .chartjs-render-monitor {
        position: relative;
        height: 188px  !important;
    }
    .answers-chart{
        width: 400px;
        overflow: auto;
        margin: 0 auto;
    }
    .font-size-14 {
        font-size: 14px;
    }
</style>
