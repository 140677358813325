<template>
    <v-card color="grey lighten-5" class="my-3">
        <!-- テーブル -->
        <!-- 問い合わせ履歴 -->
        <v-data-table
            v-if="inquiryHistoryFlg"
            v-model="selected"
            :headers="headers"
            :items="matterList"
            :single-select="singleSelect"
            show-select
            item-key="matterId"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            hide-default-footer
            class="elevation-4 mx-3"
            :item-class="itemRowColorText"
            no-data-text="テスト"
            @click:row="clickRow">
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-dialog
                  v-model="dialogDelete"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                      :disabled="!isDeleted"
                      @click="confirmDelete()"
                      v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest"
                    >
                      削除
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">削除してもよろしいでしょうか。</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn rounded color="primary" large class="px-7" @click="deleteMatters">はい</v-btn>
                      <v-btn rounded color="warning" large  class="px-5" @click="closeDelete">キャンセル</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>

                </v-dialog>
              </v-toolbar>
            </template>

        </v-data-table>
        <!-- 問い合わせ一覧 -->
        <v-data-table
            v-else
            v-model="selected"
            :headers="headers"
            :items="matterList"
            single-select
            :item-value="(item) => item"
            :show-select="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest"
            item-key="matterId"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            hide-default-footer
            :items-per-page="itemsPerPage"
            class="elevation-4 mx-3"
            :item-class="itemRowColorText"
            no-data-text="検索条件に一致するお問い合わせはありません"
            @dblclick:row="inquiryHandleTransition">
            <template v-slot:top>
              <v-toolbar
                flat
              >
                <v-dialog
                  v-model="dialogDelete"
                  max-width="500px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-row>
                        <v-col cols="4" class="d-flex items-center" style="height: 64px; padding: 0px">
                          <v-col cols="2">
                            <v-btn
                              color="primary"
                              class="mb-2"
                              v-bind="attrs"
                              v-on="on"
                              :disabled="!isDeleted"
                              @click="confirmDelete()"
                              v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest"
                            >
                              削除
                            </v-btn>
                          </v-col>
                          <v-col cols="6" style="width: 200px" class="d-flex items-center">
                            <div class=" items-center justify-center">
                              <p class="text-subtitle-1 mt-2"> 表示件数:</p>
                            </div>
                            <div style="width: 50%">
                              <v-select
                                class="square ml-2"
                                :value="itemsPerPage"
                                :items="listRowLimit"
                                label=""
                                solo
                                flat
                                outlined
                                dense
                                @change="$emit('changePerPage', $event)"
                              ></v-select>
                            </div>
                          </v-col>
                        </v-col>
                    </v-row>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5">削除してもよろしいでしょうか。</v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn rounded color="primary" large class="px-7" @click="deleteMatters">はい</v-btn>
                        <v-btn rounded color="warning" large  class="px-5" @click="closeDelete">キャンセル</v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>

                </v-dialog>
              </v-toolbar>
            </template>
        </v-data-table>

        <!-- ページネーション -->
        <div class="text-center py-1" v-show="matterList.length > 0">
            <v-pagination :value="page" :length="pageCount" total-visible="7" @input="$emit('changePage', $event)"></v-pagination>
        </div>
    </v-card>
</template>

<script>
import updateMatterService from '@/services/updateMatterService.js';
export default {
    props: {
        // テーブルに表示するデータ(バックエンドから取得)
        matterList: Array,
        itemCount: Number,
        inquiryHistoryFlg: Boolean,
        pageCount: Number,
        page: Number,
        sortParam: Object,
        silentLoad: Boolean,
        itemsPerPage: Number,
    },
    data() {
        return {
            // テーブルに表示する行数
            listRowLimit: [10, 20, 50, 100],
            sortBy: null,
            sortDesc: null,
            singleSelect: false,
            selected: null,
            dialogDelete: false,
            isDeleted: false,
            // テーブルのヘッダー
            headers: [
                {
                  text: '方法',
                  align: 'start',
                  value: 'inquiryTypeName',
                },
                {
                  text: '問い合わせ日時',
                  value: 'inquiryDate'
                },
                {
                  text: '会社名',
                  value: 'customerCompany',
                },
                {
                  text: 'お客様名',
                  value: 'customerName'
                },
                {
                  text: '電話番号１',
                  value: 'customerPhoneNum1'
                },
                {
                  text: 'タイトル',
                  value: 'matterTitle'
                },
                {
                  text: '状態',
                  value: 'statusName'
                },
                {
                  text: '担当者',
                  value: 'fullNameUser'
                },
                {
                  text: '回答方法',
                  value: 'matterReturnMethod'
                },
            ],
            // 問い合わせ履歴の案件フラグ
            inquiryHistoryMatterFlg: false,
        }
    },
    methods: {
        itemRowColorText: function (item) {
          return item.matterUrgency === 2 ? 'text-color-orange' : (item.matterUrgency === 1  ? 'text-color-red' : '');
        },
        // テーブルの行をダブルクリックした時のイベント
        inquiryHandleTransition(event, { item }) {
            // 画面遷移先のpath
            const path = `/inquiryhandle/${item.matterId}`;
            // storeから「navbarMatterLists」を取得
            let navbarMatterList = this.$store.getters.getMatterList.concat(this.$store.getters.getOShopMatterList, this.$store.getters.getSIDMatterList, this.$store.getters.getMOMatterList)
            // Vuexの「navbarMatterLists」に既に登録済の案件だった場合、追加処理は行わない
            for (let i = 0; i < navbarMatterList.length; i++) {
                if (navbarMatterList[i].matterLink === path) {
                    // 対応画面へ画面遷移
                    this.$router.push(path);
                    return;
                }
            }
            // Vuexの「navbarMatterLists」へ対応ページの情報をpush
            const newNavbarMatter = {
                matterId: item.matterId,
                matterTitle: item.matterTitle,
                matterLink: path,
                matterPhone: item.inquiryType ? true : false,
                matterUrgency:  item.matterUrgency,
                serverFlag: item.serverFlag
            };
            this.$store.dispatch('updateNavbarMatterListAction', newNavbarMatter);
            // 対応画面へ画面遷移
            this.$router.push(path);
        },
        // 問い合わせ履歴モーダル
        clickRow(item) {
            console.log('クリックした行の案件情報:',item);
            this.itemsPerPage = 5;
            this.$emit("inquiry-history-matter", item);
        },

        //delete matter
        confirmDelete () {
          if(this.silentLoad){
            this.activateErrorNotification({message: '取得処理が終わってからやり直してください'})
          } else this.dialogDelete = true
        },
        async deleteMatters(){
          if(this.silentLoad){
            this.activateErrorNotification({message: '取得処理が終わってからやり直してください'})
          } else{
            const matterId = this.selected[0].matterId;
            await updateMatterService.deleteMatter(matterId)
              .catch(error => {
                if(error.response.status === 403){
                  const editing_by = error.response.data.message.editing_by;
                  this.$emit('isEditing',editing_by);
                }
              });
            this.dialogDelete = false;
            this.$emit('updateMatterList');
          }
        },
        closeDelete () {
          this.dialogDelete = false
        }
    },
  watch: {
    async sortParam(){
      this.sortBy = this.sortParam.sortBy;
      this.sortDesc = this.sortParam.sortDesc;
    },
    async sortBy(){
      this.sortParam.sortBy = this.sortBy;
      this.$emit('sortParam', this.sortParam);
    },
    async sortDesc(){
      this.sortParam.sortDesc = this.sortDesc;
      this.$emit('sortParam', this.sortParam);
    },
    dialogDelete (val) {
      val || this.closeDelete();
    },
    selected (val) {
      if(val.length){
        this.isDeleted = true;
      }else{
        this.isDeleted = false;
      }
    }
  },
    mounted() {
        if (typeof this.itemCount !== 'undefined') {
            this.itemsPerPage = this.itemCount;
        }
    }
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
    font-size: 18px !important;
    background-color: #90caf9;
}
/* テーブルデータのフォントサイズ */
.v-data-table ::v-deep td {
    /* font-size: 10px !important; */
    background-color: #e3f2fd;
}
.v-data-table ::v-deep tr:nth-child(odd) td {
    background: #fff;
}

.v-data-table ::v-deep tr:hover td {
    background-color: #eee;
}
::v-deep .v-data-table__empty-wrapper > td {
    font-size: 20px !important;
    /* font-family: sans-serif !important; */
}
>>>tr.text-color-red{
  color: red;
}

>>>tr.text-color-orange{
  color: orange;
}
</style>
