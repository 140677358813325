<template>
  <div style="background-color: #E0E0E0; height: 100%; padding-top: 1px;">
    <v-card color="grey lighten-5" class="ma-3" style="height: 97%">
      <v-card-title class="py-1">取り込み状況</v-card-title>
      <v-divider class="mx-4"></v-divider>
      <upload-list-table :product-upload-list="productUploadList" @deleteProductUpload="deleteProductUpload"/>
      <v-overlay :value="loadingCount > 0">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>
<script>

import UploadListTable from "@/components/parts/UploadListTable";
import productUploadService from "@/services/productUploadService";
import importUploadService from "@/services/importUploadService";
import {timeStampFormat} from "@/components/helpers/utils";

export default {
  components: {
    UploadListTable,
  },
  // 画面表示時に行う処理
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.storeHeaderTitleUpdate();
      next();
    });
  },
  data() {
    return{
      loadingCount: 0,
      productUploadList: [],
      main_matter: ['', 'アンケートマスタ'],
    }
  },
  methods: {
    // VuexのstateのheaderTitleを更新
    storeHeaderTitleUpdate() {
      this.$store.dispatch('headerTitleChangeAction', 7);
    },
    async getProductUploadList() {
      this.loadingCount++;

      const [productUploadResponse, importUploadResponse] = await Promise.all([
        productUploadService.getProductUploadList(),
        importUploadService.getImportUploadList()
      ]);

      // Process product upload list
      this.productUploadList = productUploadResponse.data.data.map(item => {
        item.created_at = timeStampFormat(item.created_at);
        item.import_type = 0;
        item.name_main = this.main_matter[0];
        return item;
      });

      // Process import upload list and append to the same list
      const importUploads = importUploadResponse.data.data.map(item => {
        item.document_name = item.file_name;
        item.status = item.import_status;
        item.name_main = this.main_matter[item.import_type];
        item.created_at = timeStampFormat(item.created_at);
        return item;
      });

      this.productUploadList = [
        ...this.productUploadList,
        ...importUploads
      ].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      this.loadingCount--;
    },
    deleteProductUpload() {
      this.getProductUploadList();
    }
  },
  mounted() {
    this.getProductUploadList();
  }
}
</script>
<style scoped>

</style>
