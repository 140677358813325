<template>
  <vue-final-modal
    v-model="notificationModalFlg"
    classes="modal-container"
    :content-class="`modal-content ResizeMain`"
    :ssr="true"
    :click-to-close="false"
    :min-width="350"
    :min-height="400"
    :max-width="Infinity"
    :max-height="Infinity"
  >
    <span class="modal__title">
      <v-row class="modal__move">
        <v-toolbar color="primary" dark>
          <span class="text-h5 ml-3">お知らせ一覧検索</span>
        </v-toolbar>
        <v-btn style="border-radius: 0px; box-shadow: none;" class="px-10" color="primary" height="65px" width="40px"
          @click="mailModalCreateFlgClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-row>
    </span>
    <div class="modal__content">
      <v-card outlined color="white">
        <!-- お問い合わせ管理 -->
        <v-row align="center" no-gutters>
          <!-- 商品検索 -->
          <v-col cols="2" class="d-flex justify-center">
            <v-subheader class="float-none">商品検索</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field v-model="relatedProducts" clearable label="関連商品" hide-details outlined dense @keyup.enter="onSearch"
              class="mini-size"></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
          <!-- タイトル、文章検索 -->
          <v-col cols="2" class="d-flex justify-center">
            <v-subheader>タイトル</v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field v-model="title" clearable label="タイトル" hide-details outlined dense @keyup.enter="onSearch"
              class="mini-size"></v-text-field>
          </v-col>
        </v-row>
        <v-card-text class="card-notification-table">
          <!-- 検索結果 -->
          <v-data-table
            height="480"
            fixed-header
            :headers="headers"
            :items="notifications"
            :items-per-page="-1"
            :sort-by="['updatedAt']"
            :sort-desc="[true]"
            :loading="loadingTableCount > 0"
            loading-text="データを読み込みしています。"
            show-select v-model="selectedNotifications"
            @dblclick:row="updateRowNotification"
            item-key="id"
            no-data-text="検索条件に一致するお知らせはありません"
            hide-default-footer
            class="elevation-2 notification_table"
            :item-class="itemRowColorText"
            dense
            @input="itemSelected"
          >
            <template v-slot:item.data-table-select="{ item, isSelected, select, on }">
              <div v-on='on'>
                <v-simple-checkbox
                  v-show="!item.deleteAt"
                  :value="isSelected"
                  @input="select($event)"
                  color="primary"
                >
                </v-simple-checkbox>
              </div>
            </template>
            <template #[`item.createdAt`]="props">
              <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}"
                style="display: inline-block; width: 117px">{{
                props.item.createdAt.slice(0, -3)
                }}</span>
            </template>
            <template #[`item.updatedAt`]="props">
              <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}"
                style="display: inline-block; width: 117px">
                {{ props.item.updatedAt.slice(0, -3) }}
              </span>
            </template>
            <template #[`item.title`]="props">
              <a :href="'#'" @click.prevent="viewNotificationDetails(props.item)" @dblclick.stop>
                <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
                  {{ props.item.title }}
                </span>
              </a>
            </template>
            <template #[`item.relatedProducts`]="props">
              <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}"
                style="display: inline-block; width: 350px">
                {{ props.item.relatedProducts }}
              </span>
            </template>
            <template #[`item.postPeriod`]="props">
              <span v-show="props.item.notificationStartDate === null"
                style="display: inline-block; width: 72px"></span>
              <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
                {{ props.item.postPeriod }}
              </span>
              <span v-show="props.item.notificationEndDate === null" style="display: inline-block; width: 72px"></span>
            </template>
            <template #[`item.createdBy`]="props">
              <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
                {{ props.item.createdBy }}
              </span>
            </template>
            <template #[`item.updatedBy`]="props">
              <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
                {{ props.item.updatedBy }}
              </span>
            </template>
            <template v-slot:foot>
              <v-row v-intersect="infiniteScrolling" ref="tableNotificationscrollRow"></v-row>
            </template>
          </v-data-table>
          <v-row align="center" class="py-3 px-3" justify="space-between">
            <div class="py-3">
              <v-btn color="primary mr-3" height="32px" @click="openDialogForCreating">新規作成</v-btn>
              <v-btn height="32px" color="error" :disabled="!selectedNotifications.length || !dataSelect"
                @click="openDialogForDeleting">削除
              </v-btn>
              <v-btn class="ml-3" height="32px" color="success" :disabled="!selectedNotifications.length || !dataSelect"
                @click="readAllNotifications">一括既読
              </v-btn>
            </div>
            <v-checkbox
              v-model="nonRequestItem"
              :label="'要望事項以外を表示'"
              class="mini-size"
              dense
              hide-details
            ></v-checkbox>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row align="center" class="modal__move btn_bottom" justify="center">
        <v-btn rounded color="success" class="px-12 mx-5" height="38px" @click="onSearch">入力した内容で検索
        </v-btn>
        <v-btn rounded color="warning" class="px-10 mx-5" height="38px" @click="resetDataSearch">入力クリア
        </v-btn>
      </v-row>
      <!-- お知らせ詳細ダイアログ -->
      <notification-details-dialog
        v-bind="notification_dialog"
        :dialog.sync="detailsDialog"
        @close-dialog="
              notification_dialog = null;
              detailsDialog = false;
            "/>
      <!-- お知らせ編集ダイアログ-->
      <notification-edit-dialog
        v-bind="notification_dialog"
        :validation-errors="validationErrors"
        :dialog.sync="editDialog"
        @create-notification="createNotification"
        @update-notification="updateNotification"
        @close-dialog="
              notification_dialog = null;
              editDialog = false;
              validationErrors = {};
            ">
        <template v-slot:snackbarValidationErrors>
          <v-snackbar v-model="snackbar" color="error" top :timeout="3000">
            <v-layout align-center>
              <v-layout column>
                <div>
                  <strong>エラー</strong>
                </div>
                <div v-for="(message, index) in validationErrors.title ? validationErrors.title : []"
                    :key="`title-${index}`">
                  {{ message }}
                </div>
                <div v-for="(message, index) in validationErrors.notificationStartDate ? validationErrors.notificationStartDate : []"
                    :key="`start-date-${index}`">
                  {{ message }}
                </div>
                <div v-for="(message, index) in validationErrors.notificationEndDate ? validationErrors.notificationEndDate : []"
                    :key="`end-date-${index}`">
                  {{ message }}
                </div>
              </v-layout>
            </v-layout>
          </v-snackbar>
          <v-dialog v-model="snackbarUpdateSuccess" scrollable max-width="500px">
            <v-card>
              <v-card-title class="text-h5" style="margin: auto">お知らせ情報を更新しました。</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="primary" large class="px-15" @click="snackbarUpdateSuccess = false">確認</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="snackbarCreateSuccess" scrollable max-width="500px">
            <v-card>
              <v-card-title class="text-h5" style="margin: auto">お知らせ情報を投稿しました。</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="primary" large class="px-15" @click="snackbarCreateSuccess = false">確認</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </notification-edit-dialog>
      <!-- 削除確認ダイアログ -->
      <v-dialog v-model="deleteDialog" width="500px">
        <v-card>
          <v-card-title class="text-h6">お知らせの削除確認</v-card-title>
          <v-card-text v-html="delMsg" style="font-size: 18px"></v-card-text>
          <v-card-actions>
            <!-- 閉じるボタン -->
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="deleteNotifications">削除</v-btn>
            <v-btn color="primary" @click="deleteDialog = false">閉じる</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-overlay :value="loadingCount > 0">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </div>
  </vue-final-modal>
</template>

<script>
import notificationService from "@/services/notificationService";
import Ripple from 'vuetify/lib/directives/ripple';
import NotificationEditDialog from '@/components/parts/NotificationEditDialog.vue';
import NotificationDetailsDialog from '@/components/parts/NotificationDetailsDialog.vue';

export default {
    components: {
        'notification-details-dialog': NotificationDetailsDialog,
        'notification-edit-dialog': NotificationEditDialog,
    },
    props: {
      // テーブルに表示するデータ(バックエンドから取得)
      notificationModalFlg: Boolean,
    },
    directives: {
      Ripple,
    },
    data() {
        return {
            relatedProducts: '',
            title: '',
            isSearch: false,
            detailsDialog: false,
            deleteDialog: false,
            editDialog:false,
            notifications: [],
            notification_dialog: null,
            validationErrors: {},
            notificationTblKey: 0,
            page: 1,
            pageCount: null,
            loadingCount: 0,
            loadingTableCount: 0,
            delMsg: '',
            headers: [
              {text: 'id', value: 'id', align: ' d-none'},
              {text: '投稿日時', value: 'createdAt'},
              {text: '更新日時', value: 'updatedAt'},
              {text: 'タイトル', value: 'title'},
              {text: '関連商品', value: 'relatedProducts'},
              {text: '掲載期間', value: 'postPeriod'},
              {text: '投稿者', value: 'createdBy'},
              {text: '更新者', value: 'updatedBy'},
            ],
            selectedNotifications: [],
            snackbar:false,
            snackbarCreateSuccess: false,
            snackbarUpdateSuccess: false,
            dataSelect: false,
            Valid_Read_Status: 1,
            nonRequestItem: false,
        }
    },
    methods: {
      mailModalCreateFlgClose() {
        this.$emit('changeNotificationModalFlg', false)
      },
      async onSearch() {
        this.notifications = [];
        this.isSearch = true;
        this.$refs.tableNotificationscrollRow.scrollTop = 0;
        if (this.page == 1) {
          await this.searchNotifications(this.page);
        } else {
          this.page = 1;
        }
      },
      //scroll to load
      async infiniteScrolling(entry) {
        if (this.notificationModalFlg
          && entry[0].isIntersecting
          && this.page < this.pageCount
        ) {
          this.page++;
        }

      },
      async searchNotifications(page = null) {
        this.loadingTableCount++;
        if (page) this.page = page
        // 検索条件を取得してお知らせを取得
        const conditions = {
          start_date: this.startDate,
          end_date: this.endDate,
          related_products: this.relatedProducts,
          title: this.title,
          contain_deleted: 0,
          contain_out_of_period: 0,
          contain_improvement_flg: 0,
          contain_non_request_item: this.nonRequestItem ? 1 : 0,
          page: this.page
        };
        const response = await notificationService.searchNotifications(conditions);
        this.pageCount = response.data.data.last_page;
        let searchResulNotificationList = response.data.data.data.map((notification) => {
          let postPeriod;
          postPeriod =
            notification.notificationStartDate === null
              ? '          '
              : notification.notificationStartDate;
          postPeriod += ' ～ ';
          postPeriod +=
            notification.notificationEndDate === null
              ? '          '
              : notification.notificationEndDate;
          notification.postPeriod = postPeriod;
          return notification;
        });
        if (searchResulNotificationList.length > 0) {
          searchResulNotificationList.forEach(item => {
            const existingIndex = this.notifications.findIndex(notification => {
              return notification.id === item.id
            })
            if (existingIndex === -1) this.notifications.push(item);
          });
          if(this.nonRequestItem){
            this.notifications = this.notifications.filter(item => item.improvementFlg !== 1);
          }
        }
        this.loadingTableCount--;
      },
      openDialogForViewing(item) {
        this.notification_dialog = item;
        this.detailsDialog = true;
        this.validationErrors = {};
      },
      openDialogForEditing(item) {
        this.notification_dialog = item;
        this.editDialog = true;
      },
      openDialogForCreating() {
        this.notification = null;
        this.editDialog = true;
        this.validationErrors = {};
      },
      openDialogForDeleting() {
        this.notificationsForDeleting = this.selectedNotifications;
        const notificationsCount = this.selectedNotifications.length;
        this.delMsg = `${notificationsCount}個のお知らせが削除されます。宜しいでしょうか？`;
        this.deleteDialog = true;
      },
      async updateUnreadNotify() {
        let response = await notificationService.countUnreadNotifications();
        this.$store.commit('updateUnreadNotify', response.data.amount);
      },
      async createNotification(notification) {
        notification.improvementFlg = 0;
        if (notification.notificationEndDate < notification.notificationStartDate) {
          this.editDialog = true;
          this.validationErrors.notificationEndDate = ["掲載終了日は掲載開始日より後ろの日付に設定してください"];
          this.snackbar = true;
        } else {
          // APIを呼び出し、保存処理を行う
          try {
            this.loadingCount++;
            await notificationService.createNotification(notification);
            this.updateUnreadNotify();
            this.snackbarCreateSuccess = true;
            this.loadingCount--;
          } catch (e) {
            console.log(e)
            this.editDialog = true;
            if (e.response && e.response.status === 422) {
              this.validationErrors = e.response.data.errors;
              this.snackbar = true;
            }
            this.loadingCount--;
            return;
          }
          this.notification = null;
          this.selectedNotifications = [];
          this.validationErrors = {};
          // お知らせを再取得し、テーブルを更新する
          await this.onSearch();
          // 検索結果部分を初期状態に更新
          this.notificationTblKey++;
        }
      },
      async updateNotification(notification) {
        if (notification.notificationEndDate < notification.notificationStartDate) {
          this.editDialog = true;
          this.validationErrors.notificationEndDate = ["掲載終了日は掲載開始日より後ろの日付に設定してください"];
          this.snackbar = true;
        } else {
          // APIを呼び出し、保存処理を行う
          try {
            this.loadingCount++;
            await notificationService.updateNotification(
              notification.id,
              notification
            );
            this.updateUnreadNotify();
            this.snackbarUpdateSuccess = true;
            this.loadingCount--;
          } catch (e) {
            this.editDialog = true;
            if (e.response && e.response.status === 422) {
              this.validationErrors = e.response.data.errors;
              this.snackbar = true;
            }
            this.loadingCount--;
            return;
          }
          this.notification_dialog = null;
          this.selectedNotifications = [];
          this.validationErrors = {};
          // お知らせを再取得し、テーブルを更新する
          await this.onSearch();
          // 検索結果部分を初期状態に更新
          this.notificationTblKey++;
          this.loadingCount--;
        }
      },
      async deleteNotifications() {
        this.loadingCount++;
        this.deleteDialog = false;
        // APIを呼び出し、削除処理を行う
        await notificationService.deleteNotifications(this.notificationsForDeleting);
        this.updateUnreadNotify();
        this.notificationsForDeleting = [];
        this.selectedNotifications = [];
        // お知らせを再取得し、テーブルを更新する
        await this.onSearch();
        // 検索結果部分を初期状態に更新
        this.notificationTblKey++;
        this.loadingCount--;
      },
      async readAllNotifications() {
        let item = this.selectedNotifications;
        if (item) {
          for (let i in item) {
            let notification = item[i];
            if (notification.read_status == 0) {
              this.loadingCount++;
              notification.read_status = this.Valid_Read_Status;
              const conditions = {
                notificationId: notification.id,
                readStatus: 1
              };
              await notificationService.createNotificationReader(conditions);
              this.loadingCount--;
            }
          }
          await this.updateUnreadNotify();
          this.selectedNotifications = [];
          await this.onSearch();
        }
      },
      async resetDataSearch() {
        this.relatedProducts = '';
        this.title = '';
        this.selectedNotifications = [];
        await this.onSearch();
      },
      itemRowColorText: function (item) {
        return item.matterUrgency === 2 ? 'text-color-orange' : (item.matterUrgency === 1 ? 'text-color-red' : '');
      },
      async viewNotificationDetails(item) {
        item.read_status = this.Valid_Read_Status;
        const conditions = {
          notificationId: item.id,
          readStatus: 1
        };
        try {
          await notificationService.createNotificationReader(conditions);
          this.openDialogForViewing(item);
          this.updateUnreadNotify();
        } catch (e) {
          console.log(e)
        }
      },
      async updateRowNotification(event, { item }) {
        item.read_status = this.Valid_Read_Status;
        const conditions = {
          notificationId: item.id,
          readStatus: 1
        };
        try {
          await notificationService.createNotificationReader(conditions);
          this.openDialogForEditing(item);
          this.updateUnreadNotify();
        } catch (e) {
          console.log(e)
        }
      },
      itemSelected(list) {
        let data = list.filter(item => !item.deleteAt)
        if (data.length == 0) {
          this.dataSelect = false
        } else {
          this.dataSelect = true
        }
        return data
      },
    },
    watch: {
      async page() {
        if (this.notificationModalFlg) {
          await this.searchNotifications(this.page);
        }
      },
      async notificationModalFlg(){
        if (this.notificationModalFlg) {
          this.notifications = [];
          this.selectedNotifications = [];
          this.isSearch = false;
          this.relatedProducts = '';
          this.title = '';
          this.nonRequestItem = false;
          await this.onSearch(true);
        }
      }
    },
}
</script>
<style scoped>
  ::v-deep .modal-container {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  ::v-deep .modal-content {
      display: flex;
      flex-direction: column;
      touch-action: none;
      position: absolute;
      margin: unset;
      background: #1976d2 !important;
  }

  ::v-deep .ResizeMain {
      max-height: 100%;
      max-height: 722px;
      width: 1299px;
  }

  ::v-deep .modal__content {
      width: 100%;
      height: 83%;
  }

  ::v-deep .modal-btn {
      border-radius: 0;
      box-shadow: none;
      background-color: #1976d2 !important;
      color: white;
  }
  .modal__title .modal__move {
    margin: 0px auto;
    align-items: center;
    justify-content: space-between
  }
  .modal__content .v-card {
    height: 100%;
    border-radius: 0px;
    padding: 15px;
    padding-bottom: 0px;
    align-content: space-between;
  }
  .card-notification-table {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 87%;
    padding: 10px 10px 0px 10px;
  }
  .notification_table {
    max-height: 100%;
    overflow-y: auto;
    zoom: 90%;
  }
  .btn_bottom {
    height: 60px;
    margin: 0px;
    background: white;
    padding: 0px 0px 10px 0px;
  }
  /* テーブルのヘッダーのフォントサイズ */
  .v-data-table ::v-deep th {
    padding: 5px !important;
    font-size: 18px !important;
    height: 50px !important;
    background-color: #90caf9 !important;
  }
  .v-data-table ::v-deep .v-data-table__progress th{
    padding: 0px !important;
    height: 4px !important;
  }

  /* テーブルデータのフォントサイズ */
  .v-data-table ::v-deep td {
    background-color: #e3f2fd;
    padding: 5px !important;
    height: 48px !important;
  }

  .v-data-table ::v-deep tr:nth-child(odd) td {
    background: #fff;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .v-data-table ::v-deep tr:hover td {
    background-color: #eee;
  }

  ::v-deep .v-data-table__empty-wrapper > td {
    font-size: 20px !important;
  }

  >>> tr.text-color-red {
    color: red;
  }

  >>> tr.text-color-orange {
    color: orange;
  }
</style>
<style>
  .notification_table > .v-data-table > .v-data-table__wrapper {
    max-height: 480px !important;
    overflow-y: auto;
  }
</style>
