import http from '@/httpCommon.js';

export default {
  // get data list admin
  async searchForQuestionsSurveyCsv(data) {
    let query = '';
    const key = Object.keys(data);
    const value = Object.values(data);

    for (let i = 0; i < key.length; i++) {
      if(i == 0 ) query += `?${key[i]}=${value[i]}`
      else query += `&${key[i]}=${value[i]}`
    }

    return http.get(`/questions/survey-search${query}`);
  },

  async importSurvey(data,headers){
    return http.post('/questions/import-survey-csv',data,headers);
  },

  async deleteAnswers(idAnswer) {
    return http.delete(`/questions-answers/${idAnswer}`, { headers: {enableMessage: true}});
  },

  // get data chart in dashboard
  async getSurveyReport(data) {
    return http.post(`/questions/survey/report`,{
      params: {
        startDate : data.startDate,
        endDate : data.endDate,
        noDateSpecified: data.noDateSpecified,
      }
    });
  },

}
