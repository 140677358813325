<template>
  <v-row justify="center">
    <v-dialog
      v-model="surveyMstDialog"
      persistent
      overflow-y=initial
      :width="actionMode != 0 ? '1100px':'1200px'"
    >
      <v-card>
        <v-card-title class="headline pb-4">{{this.dialogTitle}}</v-card-title>
        <v-card-text>
          <v-container fluid class="px-5">
            <v-row>
              <v-col :cols="actionMode == 2 ? '6':'5'">
                <v-card flat>
                  <v-container fluid>
                    <v-row v-if="actionMode == 2" align="center" >
                      <v-col class="text-left text-h6" >回答者ID</v-col>
                      <v-col cols="8"><v-text-field :readonly="true" outlined height=5 ma-0 pa-0 hide-details dense v-model='question_data.response_id'></v-text-field></v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col class="text-left text-h6" >{{actionMode != 0 ? '回答日時':'表示期間' }}</v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model='startDate'
                          :label="actionMode == 0 ?'開始日':''"
                          :max="maxDate"
                          :type="actionMode != 0 ? 'text':'date'"
                          :readonly="actionMode!=0"
                          outlined ma-0 pa-0 hide-details dense
                          :disabled="noDateSpecified"
                        ></v-text-field>
                        <span v-if="!noDateSpecified && !isValidStartDate" style="color: red">選択された日付は有効な日付ではありません</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col :cols="actionMode == 2 ? '6':'5'" style="margin-left: 0;">
                <v-card flat>
                  <v-container fluid>
                    <v-row v-if="actionMode == 2" align="center">
                      <v-col class="text-left text-h6" right>F1_USERID</v-col>
                      <v-col cols="8"><v-text-field :readonly="true" outlined ma-0 pa-0 hide-details dense v-model='question_data.f1_user_id'></v-text-field></v-col>
                    </v-row>
                    <v-row align="center">
                      <v-col :class="actionMode != 0 ? 'text-left text-h6':'text-center text-h5'" right>{{actionMode != 0 ? '回答開始日時':'～' }}</v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model='endDate'
                          :label="actionMode == 0 ?'終了日':''"
                          :max="maxDate"
                          :type="actionMode != 0 ? 'text':'date'"
                          :readonly="actionMode!=0"
                          outlined ma-0 pa-0 hide-details dense
                          :disabled="noDateSpecified"
                        ></v-text-field>
                        <span v-if="!noDateSpecified && !isValidEndDate" style="color: red">選択された日付は有効な日付ではありません</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
              <v-col v-if="actionMode == 0" class="text-center" style="justify-content:space-between;">
                <v-checkbox
                  ref="noDateSpecified"
                  v-model="noDateSpecified"
                  label="日付指定なし"
                  class="d-inline-flex mini-size"
                  dense
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row  v-if="actionMode!=0">
              <v-col cols="10" class="pb-0">
                <p class="mb-0 text-subtitle-1 font-weight-bold">お客様対応の顧客満足度調査</p>
              </v-col>
            </v-row>
            <v-row v-if="actionMode == 2">
              <v-col cols='12'>
                <v-card flat>
                  <v-container fluid>
                    <v-row class="ml-0">
                      <v-col cols="6">
                        <v-col v-for="(item, index) in questionsList.slice(0, Math.ceil(questionsList.length / 2))" :key="index">
                          <p class="mb-1 text-subtitle-1 font-weight-bold">{{ item.question }}</p>
                          <v-radio-group
                            v-model="selectedAnswers[index]"
                            hide-details
                            class="mt-0 pt-0">
                            <v-radio
                              :readonly="true"
                              v-for="answer in item.answers"
                              :key="answer.answer"
                              :label="answer.answer"
                              :value="answer.value"
                              class="mr-3">
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-col>

                      <v-col cols="6">
                        <v-col v-for="(item, index) in questionsList.slice(Math.ceil(questionsList.length / 2))" :key="index + Math.ceil(questionsList.length / 2)">
                          <p class="mb-1 text-subtitle-1 font-weight-bold">{{ item.question }}</p>
                          <v-radio-group
                            v-model="selectedAnswers[index + Math.ceil(questionsList.length / 2)]"
                            hide-details
                            class="mt-0 pt-0">
                            <v-radio
                              :readonly="true"
                              v-for="answer in item.answers"
                              :key="answer.answer"
                              :label="answer.answer"
                              :value="answer.value"
                              class="mr-3">
                            </v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if='this.actionMode!=2'>
            <v-btn color="primary" @click="cancelEvent" style="width: 120px;">キャンセル</v-btn>
          </template>
          <template v-if='this.actionMode==2'>
            <v-btn color="primary" @click="()=> dialogConfirmDelete = true" style="width: 120px;">削除</v-btn>
          </template>
          <v-btn color="primary" @click="okEvent" style="width: 120px;">{{okButtonName[actionMode]}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- dialog Confrim Delete -->
    <v-dialog
      v-model="dialogConfirmDelete"
      persistent
      overflow-y=initial
      width=500px
    >
      <v-card>
        <v-card-text>
          <v-container fluid>
            <v-row class="pl-0">
              <v-col cols="12" class="ml-0 px-0 pb-0">
                <v-card-title class="headline">削除してもよろしいでしょうか。</v-card-title>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template>
            <v-btn color="primary" @click="()=> dialogConfirmDelete = false" style="width: 120px;">キャンセル</v-btn>
          </template>
          <template>
            <v-btn color="primary" @click="deleteClassification" style="width: 120px;">削除</v-btn>
          </template>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-row>
</template>

<script>
import questionsSurveyCsvService from '@/services/questionsSurveyCsvService.js';
import moment from 'moment';

export default {
  name: 'masterQuestionSurveyDialog',
  props: {
    dialogTitle: String,
    surveyMstDialog: Boolean,
    actionMode: Number, /* 0:検索, 1:登録, 2:表示 */
    dialogItems: Object,
    readOnly: Boolean,
    questionsList: Array,
  },
  data: function () {
    return {
      question_data: {},
      dialogConfirmDelete: false,
      selectedAnswers: [],
      okButtonName: ['検索', '登録', '閉じる'],
      maxYear: moment().year(),
      maxDate: '',
      startDate: '',
      endDate: '',
      noDateSpecified: true,
    }
  },
  computed: {
    isValidStartDate() {
      return this.validateDate(this.startDate);
    },
    isValidEndDate() {
      return this.validateDate(this.endDate);
    }
  },
  methods: {
    cancelEvent() {
      if (this.actionMode === 2) {
        this.startDate = '';
        this.endDate = '';
      }
      this.selectedAnswers = []
      this.$emit('result', {'res': false, 'message': []})
    },
    reloadData(){
      this.$emit('reload')
    },
    async okEvent() {
      if(this.actionMode == 0){
        const format = 'YYYY-MM-DD';
        if(!(this.validateDate(this.startDate) && this.validateDate(this.endDate))) return;

        let startDate = (!this.noDateSpecified && this.startDate) ?  moment(this.startDate).format(format): '';
        let endDate = (!this.noDateSpecified && this.endDate) ?  moment(this.endDate).format(format): '';

        this.$emit('result', {'res': true, 'message': { startDate, endDate }})
      }
      this.cancelEvent()
    },
    async deleteClassification(){
      try {
        this.cancelEvent();
        this.dialogConfirmDelete = false;
        await questionsSurveyCsvService.deleteAnswers(this.question_data.answer_id)
      } catch (error) {
        this.cancelEvent();
      }
      this.reloadData()
    },
    mappedAnswers(answersSurveyId, questionsSurveyId) {
      const result = [];
      questionsSurveyId.forEach((questionId, index)=> {
        result[questionId] = answersSurveyId[index] || '';
      });
      return result;
    },
    validateDate(date) {
      const format = 'DD/MM/YYYY';
      if (date) {
        date = moment(date).format(format);
        const momentDate = moment(date, format, true);
        const year = momentDate.year();

        if (!momentDate.isValid()) return false;
        if (year.toString().length !== 4) return false;
        if (year < 1900 || year > this.maxYear) return false;

        return true;
      }
      return true;
    }
  },
  watch: {
    surveyMstDialog: function (value) {
      if (value) {
        this.question_data = { ...this.dialogItems };

        if (this.actionMode === 2) {
          const answersSurveyId = this.question_data.answers_survey_id || [];
          const questionsSurveyId = this.question_data.questions_survey_id || [];
          this.startDate = this.question_data.answer_date || '';
          this.endDate = this.question_data.answer_date_start || '';

          if (this.question_data && answersSurveyId.length) {
            const answersMap = this.mappedAnswers(answersSurveyId, questionsSurveyId);

            this.selectedAnswers = this.questionsList.map(question => Number(answersMap[question.id] || ''));
          }
        }
        this.maxDate = `${this.maxYear}-12-31`;
      }
    },
  },
   async created() {}
}
</script>
<style>
  .v-text-field input {
    font-size: 1.0em;
    height: 1.1em;
    margin: 0.1em;
    padding: 0.1em;
  }
</style>
